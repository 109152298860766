.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #f5f5f5;
  font-size: 12px;
  line-height: 1.33333333;
  font-family: inherit;
  cursor: pointer;
  color: #4e8fcc;
  padding: 10px 24px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.button:hover,
.button:focus {
  background-color: #4e8fcc;
  color: #fff;
}
.section--mainContent .subsegment {
  width: calc(50% - 12px);
}
.cb-layout3 .section--mainContent .subsegment.subsegment--1,
.cb-layout4 .section--mainContent .subsegment.subsegment--1 {
  width: calc(((100%/760*556)) - 12px);
}
.cb-layout3 .section--mainContent .subsegment.subsegment--2,
.cb-layout4 .section--mainContent .subsegment.subsegment--2 {
  width: calc(((100%/760*204)) - 12px);
}
.section--mainContent .area {
  width: calc(100% + 24px);
  margin-left: -12px;
}
.section--mainContent .area .unit {
  margin-left: 12px;
  margin-right: 12px;
  width: calc(100% - 24px);
}
.section--mainContent .area .unit.wide .body {
  width: calc(100% + 24px);
  margin-left: -12px;
}
.section--mainContent .area .unit.wide .part {
  margin-left: 12px;
  margin-right: 12px;
  width: calc(100% - 24px);
}
.section--mainContent .area .unit.wide .part.tiny {
  width: calc(50% - 24px);
}
.section--mainContent .area .unit.slim {
  width: calc(50% - 24px);
}
.cb-layout3 .section--mainContent .area.side .unit.wide .part.tiny,
.cb-layout4 .section--mainContent .area.side .unit.wide .part.tiny {
  width: calc(100% - 24px);
}
.cb-layout3 .section--mainContent .area.side .unit.slim,
.cb-layout4 .section--mainContent .area.side .unit.slim {
  width: calc(100% - 24px);
}
/*# sourceMappingURL=./screen-medium.css.map */